<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <!-- 框 -->
                    <v-alert :dismissible="false" class="yellow accent-2 black--text" dark border="left" icon="fas fa-info-circle black--text">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10">
                                <span>Pool Asset Address Detail</span>
                                <span class="ml-5">注: 此处是管理 Wallet / Ledger 作为 DEFY Holding 时所包含的资产种类，基础数据从 <router-link to='/address'>Address Management</router-link> 获取。需要给新的Pool添加资产时，先添加完Pool信息后才可以在这里管理资产。</span>
                            </v-col>
                            <v-col  cols="2" class="text-right">
                                <v-btn small outlined tile class="black--text" @click="addDialog = true">New</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="px-5 pt-0">
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <!-- 表头 -->
                    <v-data-table dense :headers="headers" :items="dataList" hide-default-footer disable-sort v-if="!loading">
                        <!-- 表数据 -->
                        <template v-slot:body="{ data }">
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="data.id">
                                    <!-- <td>{{ (indexNo - 1) * pageSize + (index + 1) }}</td> -->
                                    <!-- <td>{{ data.id }}</td> -->
                                    <td>{{ data.platform }}</td>
                                    <td>{{ data.poolAddress }}</td>
                                    <td>{{ data.symbol }}</td>
                                    <td>{{ data.coinAddress }}</td>
                                    <td>{{ data.walletAddress }}</td>
                                    <td>
                                        <v-btn tile outlined color="red" x-small @click="openDeleteDialog(data.id)">Delete</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!loading"></v-divider>
                    <!-- 底部分页 -->
                    <template>
                        <div class="text-xs-center mt-5" v-if="totalElements>0">
                            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="yellow accent-2 black--text" circle v-model="pageNo" :length="totalPage" @input="getDataList" :total-visible="20">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <!-- 新增模态框 -->
        <v-dialog v-model="addDialog" width="500" persistent>
            <v-card>
                <v-card-title>
                    <span class="fs-24">Add</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="addDialog = false">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="4">
                            <v-select v-model="addObj.platform" :items="supportedPlatforms" label="Platform" hide-details></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select v-model="selectedAssetAddress" :items="assetAddressList" item-text="name" v-if="addObj.platform == 'Wallet'" label="Name" return-object hide-details></v-select>
                            <v-select v-model="selectedBlockchainAsset" :items="blockchainAssetList" item-text="name" v-else-if="addObj.platform == 'Ledger'" label="Name" return-object hide-details></v-select>
                            <v-select :items="[]" v-else label="Symbol" disabled hide-details></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="Symbol" clearable v-model="addObj.symbol" disabled hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Coin Address" clearable v-model="addObj.coinAddress" v-if="addObj.platform == 'Wallet'" disabled hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="selectedPoolAddress" :items="poolAddressList" item-text="poolName" label="Pool" return-object hide-details></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Pool Address" clearable v-model="addObj.poolAddress" disabled hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Wallet Address" clearable v-model="addObj.walletAddress" v-if="addObj.platform == 'Ledger'" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small class="blue--text" outlined @click="addDialog = false" width="75" tile>Cancel</v-btn>
                    <v-btn small class="green--text mr-2" outlined @click="submit()" width="75" tile>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 刪除模态框 -->
        <v-dialog v-model="deleteDialog" width="500" persistent>
            <v-card>
                <v-card-title>
                    <span class="fs-24">Delete?</span>
                    <v-spacer></v-spacer>
                    <v-btn small class="blue--text" outlined @click="deleteDialog = false" width="75" tile>Cancel</v-btn>
                    <v-btn small class="green--text ml-2 mr-2" outlined @click="submitDelete()" width="75" tile>OK</v-btn>
                </v-card-title>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                    // { text: 'No'},
                    { text: 'Platform' },
                    { text: 'Pool Address' },
                    { text: 'Symbol' },
                    { text: 'Coin Address' },
                    { text: 'Wallet Address' },
                    { text: 'Action' },
                ],
                dataList: [],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 20,
                date: null,
                indexNo: 0,
                loading: false,
                // 支持的平台
                supportedPlatforms: [],
                // 资产地址对象集合
                assetAddressList: [],
                selectedAssetAddress: null,
                // 区块链资产对象集合数据
                blockchainAssetList: [],
                selectedBlockchainAsset: null,
                // 池子资产对象集合
                poolAddressList: [],
                selectedPoolAddress: null,
                // 新增模态框
                addDialog: false,
                addObj:{
                    platform: null,
                    symbol: null,
                    poolAddress: null,
                    coinAddress: null,
                    walletAddress: null,
                },
                // 删除窗口
                deleteDialog: false,
                // 要删除的ID
                deletedId: null
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            'addObj.platform'(newVal, oldVal){
                this.selectedAssetAddress = null;
                this.selectedBlockchainAsset = null;
                this.selectedPoolAddress = null;
                this.addObj.symbol = null;
                this.addObj.coinAddress = null;
                this.getPoolAddressList();
            },
            selectedAssetAddress: {
                handler(newObj, oldObj) {
                    if (this.addObj.platform == 'Wallet') {
                        if (this.selectedAssetAddress != null) {
                            this.addObj.symbol = this.selectedAssetAddress.symbol;
                            this.addObj.coinAddress = this.selectedAssetAddress.address;
                        } else {
                            this.addObj.symbol = null;
                            this.addObj.coinAddress = null;
                        }
                    }
                },
                deep: true  // 可以深度检测到 obj 对象的属性值的变化
            },
            selectedBlockchainAsset: {
                handler(newObj, oldObj) {
                    if (this.addObj.platform == 'Ledger') {
                        if (this.selectedBlockchainAsset != null) {
                            this.addObj.symbol = this.selectedBlockchainAsset.currency;
                            this.addObj.coinAddress = null;
                        } else {
                            this.addObj.symbol = null;
                            this.addObj.coinAddress = null;
                        }
                    }
                },
                deep: true  // 可以深度检测到 obj 对象的属性值的变化
            },
            selectedPoolAddress: {
                handler(newObj, oldObj) {
                    if (this.selectedPoolAddress != null) {
                        this.addObj.poolAddress = this.selectedPoolAddress.address;
                    } else {
                        this.addObj.poolAddress = null;
                    }
                },
                deep: true  // 可以深度检测到 obj 对象的属性值的变化
            },
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
            this.getDataList(1);
            this.getSupportedPlatforms();
            this.getAssetAddressList();
            this.getBlockchainAssetList();
        },
        created() {

        },
        methods: {
            // 获取访问数据
            getDataList(pageNo) {
                this.loading = true;
                let api = config.baseUrl + '/pool/asset/private/pool-asset-address/list?pageNo=' + (this.pageNo - 1) + '&pageSize=' + this.pageSize;
                this.$http.get(api).then(response => {
                    this.loading = false;
                    if (response.data.code == 200) {
                        this.dataList = response.data.result.dataList;
                        this.totalElements = response.data.result.totalElements;
                        this.totalPage = Math.ceil(this.totalElements / this.pageSize);
                        this.indexNo = pageNo;
                    }
                })
            },
            // 获取支持的平台
            getSupportedPlatforms(){
                let api = config.baseUrl + '/pool/asset/public/platforms/supported';
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        this.supportedPlatforms = data.result;
                    }
                })
            },
            // 查询资产地址对象集合
            getAssetAddressList() {
                let api = config.baseUrl + "/admin/private/assetAddress";
                this.$http.get(api).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                      this.assetAddressList = data.result;
                    }
                });
            },
            // 获取区块链资产对象集合数据
            getBlockchainAssetList() {
                let api = config.baseUrl + '/blockchain/asset/private/list/all';
                this.$http.get(api).then(response => {
                    if (response.data.code == 200) {
                        this.blockchainAssetList = response.data.result;
                    }
                })
            },
            // 获取池子资产对象集合数据
            getPoolAddressList() {
                this.poolAddressList = [];
                if (this.addObj.platform == null) {
                    return;
                }
                let api = config.baseUrl + '/pool/public/addresses?platform=' + this.addObj.platform;
                this.$http.get(api).then(response => {
                    if (response.data.code == 200) {
                        this.poolAddressList = response.data.result;
                    } else {
                        this.poolAddressList = [];
                    }
                })
            },
            // 提交请求
            submit(){
                let api = config.baseUrl + "/pool/asset/private/pool-asset-address/add";
                this.$http.post(api, this.addObj).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.addDialog = false;
                        let addObj = {
                            platform: null,
                            symbol: null,
                            poolAddress: null,
                            coinAddress: null,
                            walletAddress: null
                        };
                        this.addObj = addObj;
                        this.getDataList();
                    } else {
                        this.$store.dispatch("snackbarMessageHandler", data.message);
                    }
                })
            },
            // 打开删除窗口
            openDeleteDialog(id){
                this.deletedId = id;
                this.deleteDialog = true;
            },
            // 提交删除
            submitDelete(){
                let api = config.baseUrl + '/pool/asset/admin/private/delete?id=' + this.deletedId;
                this.$http.delete(api).then(response => {
                    if (response.data.code == 200) {
                        this.getDataList();
                    }
                    this.deletedId = null;
                    this.deleteDialog = false;
                })
            }
        }
    }
</script>